export const Installed = () => {
  const param = new URLSearchParams(document.location.search);

  let source = param.get("source");
  let creative_id = param.get("creative_id");
  let ad_campaign_id = param.get("ad_campaign_id");
  let offer = param.get("offer");
  let creo_id = param.get("creo_id");
  let external_id = param.get("external_id");
  let site_id = param.get("site_id");
  let cost = param.get("cost");
  let sub_id_1 = param.get("sub_id_1");
  let sub_id_2 = param.get("sub_id_2");
  let sub_id_3 = param.get("sub_id_3");
  let id = param.get("id");

  if (!id) {
    id = localStorage.getItem("id");
  }

  if (!sub_id_1) {
    sub_id_1 = localStorage.getItem("sub_id_1");
  }

  if (!sub_id_2) {
    sub_id_2 = localStorage.getItem("sub_id_2");
  }

  if (!sub_id_3) {
    sub_id_3 = localStorage.getItem("sub_id_3");
  }

  if (!cost) {
    cost = localStorage.getItem("cost");
  }

  if (!creo_id) {
    creo_id = localStorage.getItem("creo_id");
  }

  if (!external_id) {
    external_id = localStorage.getItem("external_id");
  }

  if (!site_id) {
    site_id = localStorage.getItem("site_id");
  }

  if (!source) {
    source = localStorage.getItem("source");
  }

  if (!creative_id) {
    creative_id = localStorage.getItem("creative_id");
  }

  if (!ad_campaign_id) {
    ad_campaign_id = localStorage.getItem("ad_campaign_id");
  }

  if (!offer) {
    offer = localStorage.getItem("offer");
  }

  setTimeout(() => {
    if (id === "1545") {
      window.location.href =
        `https://arumo.top/YQTXktmq?a=b` +
        (creative_id !== null ? `&creative_id=${creative_id}` : "") +
        (source !== null ? `&source=${source}` : "");
    } else if (id === "1544") {
      window.location.href =
        `https://arumo.top/Kx37GqwK?a=b` +
        (creative_id !== null ? `&creative_id=${creative_id}` : "") +
        (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
        (source !== null ? `&source=${source}` : "");
    } else if (id === "1564") {
      window.location.href =
        `https://arumo.top/bKY7hzhW?a=b` +
        (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "");
    } else if (id === "1567") {
      window.location.href =
        `https://arumo.top/NmN4XwHY?a=b` +
        (external_id !== null ? `&external_id=${external_id}` : "") +
        (creo_id !== null ? `&creo_id=${creo_id}` : "") +
        (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
        (site_id !== null ? `&site_id=${site_id}` : "");
    } else if (id === "1569") {
      window.location.href =
        `https://arumo.top/95GQm1np?a=b` +
        (creative_id !== null ? `&creative_id=${creative_id}` : "") +
        (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
        (source !== null ? `&source=${source}` : "");
    } else if (id === "1571") {
      window.location.href =
        `https://arumo.top/QYhVS4qx?a=b` +
        (creative_id !== null ? `&creative_id=${creative_id}` : "") +
        (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
        (source !== null ? `&source=${source}` : "");
    } else if (id === "1573") {
      window.location.href =
        `https://arumo.top/6J18st6x?a=b` +
        (creative_id !== null ? `&creative_id=${creative_id}` : "") +
        (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
        (source !== null ? `&source=${source}` : "");
    } else if (id === "1575") {
      window.location.href =
        `https://arumo.top/6RZ4XLRF?a=b` +
        (creative_id !== null ? `&creative_id=${creative_id}` : "") +
        (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
        (source !== null ? `&source=${source}` : "");
    } else if (id === "1577") {
      window.location.href =
        `https://arumo.top/6fyfMjN1?a=b` +
        (cost !== null ? `&cost=${cost}` : "") +
        (external_id !== null ? `&external_id=${external_id}` : "") +
        (creative_id !== null ? `&creative_id=${creative_id}` : "") +
        (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
        (source !== null ? `&source=${source}` : "");
    } else if (id === "1683") {
      window.location.href =
        `https://arumo.top/JtDLyNNW?a=b` +
        (creative_id !== null ? `&creative_id=${creative_id}` : "") +
        (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
        (source !== null ? `&source=${source}` : "");
    } else {
      window.location.href =
        `https://arumo.top/YQTXktmq?a=b` +
        (creative_id !== null ? `&creative_id=${creative_id}` : "") +
        (source !== null ? `&source=${source}` : "");
    }
  }, 800);

  setTimeout(() => {
    document.getElementById("openApp").click();
  }, 17400);

  return <div></div>;
};
